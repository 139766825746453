import React from 'react'

import VdpAlpha from '../../components/template/VdpAlpha.template'

const VdpView = () => {
    return (
        <div className='iv-page'>
            <VdpAlpha />
        </div>
    )
}

export default VdpView
