import React from 'react'
import {
    CompareButton,
    CarouselSimilar,
    GallerySlider,
    PaymentOptions,
    PromotionCard,
    RecentViews,
    SharingPanel,
    VehicleCertified,
    VehicleDescription,
    VehicleHistory,
    VehicleName,
    VehicleOptions,
    VehicleRebates,
    VehicleTagline,
    VehicleSpecs,
    VehicleDisclaimer,
    VdpBreadcrumb,
    VehicleHighlightCards,
    ArticleCard,
} from '@sm360/components'

import { VdpContext } from '../../configs/vdp-view/state.manager'
import { useTranslation } from '@sm360/hooks'
import { resetParentsOverflow } from '@sm360/helpers/dist/global-functions'
import { getImageUrl } from '@sm360/helpers'

const pictureConfiguration = {
    newVehiclePlaceholder:
        'https://img.sm360.ca/ir/w400h300c/images/web/humberviewgroup-941/4011/page-inventory-detail-nophoto1685036094373.jpg',
    usedVehiclePlaceholder:
        'https://img.sm360.ca/ir/w400h300c/images/web/humberviewgroup-941/4011/page-inventory-detail-nophoto1685036094373.jpg',
}

const VdpAlpha = () => {
    const baseClass = 'iv-vdpAlpha'
    const {
        state: {
            vehicle,
            domain,
            similarVehicles,
            hubName,
            vehiclePromo,
            showroom360ConfigVdp,
            quoteId,
            calculatorWidget,
            articleVehicle,
        },
    } = React.useContext(VdpContext)

    const {
        newVehicle,
        make,
        model,
        year,
        trim,
        certified,
        consumption,
        odometer,
        frameDescription,
        bodyStyle,
        doors,
        passengers,
        engine,
        fuel,
        transmissionDetails,
        driveTrainDetails,
        exteriorColor,
        interiorColor,
        stockNo,
        serialNo,
        pastOwner,
        severelyDamagedVehicle,
        repairHistory,
        futureReparations,
        previousUse,
        description,
        options,
        paymentOptions,
        multimedia: { pictures },
        pageViewSummary,
        tagline,
    } = vehicle

    const { detailNewHighlightCardConfigurations, detailUsedHighlightCardConfigurations } = showroom360ConfigVdp

    const highlights = newVehicle ? detailNewHighlightCardConfigurations : detailUsedHighlightCardConfigurations

    const { t } = useTranslation()

    React.useEffect(() => {
        if (document) {
            resetParentsOverflow('.iv-vdpAlpha__aside')
        }
    })

    const galleryImages = pictures.map((image) => getImageUrl(image, 'w640h480c', 'inventory'))

    return (
        <div className={`${baseClass} sr360-p-inventoryVdp`} style={{ overflow: 'hidden' }}>
            {/* TODO: The sr360 class above is temporary for demo purposes, remove later */}
            {/* Section Top */}
            <div className={`${baseClass}__navigation`}>
                <VdpBreadcrumb
                    make={make?.name}
                    model={model?.name}
                    year={year}
                    trim={trim?.name}
                    hubCategory={hubName}
                    extraClasses={`${baseClass}__breadcrumb`}
                />

                <SharingPanel />
            </div>

            <main className={`${baseClass}__main`}>
                <div className='iv-vdpAlpha__container'>
                    <VehicleTagline tagline={tagline} />

                    <div className={`${baseClass}__pictureGallery`}>
                        <div className={`${baseClass}__pictureGalleryOverlay`}>
                            <RecentViews recentViewCount={pageViewSummary?.recentViewCount} />

                            <VehicleRebates
                                rebateLabel={t('vehicle.details.rebate')}
                                rebateValue={1000}
                                extraClasses={'test-extraClasses'}
                            />
                            <CompareButton
                                vehicleId={123}
                                labelUnchecked={t('vehicle.details.compare.add')}
                                labelChecked={t('vehicle.details.compare.remove')}
                                categoryName={'new'}
                                pictoName={'add-car'}
                            />
                        </div>

                        <GallerySlider items={galleryImages} limit={8} />
                    </div>

                    <VehicleCertified
                        isCertified={certified}
                        lang='en'
                        vehicleMakeSlug='toyota'
                        dealerMakes={['toyota']}
                        imageSize='w500'
                    />

                    <VehicleDescription description={description} />

                    {highlights?.length > 0 && (
                        <VehicleHighlightCards vehicleInventory={vehicle} highlights={highlights} quoteId={quoteId} />
                    )}

                    <VehicleOptions options={options} />

                    <VehicleSpecs
                        specs={{
                            consumption,
                            odometer,
                            frameCategory: frameDescription?.frameCategory?.frameCategory,
                            bodyStyle: bodyStyle?.name,
                            doors,
                            passengers,
                            engineType: engine?.type,
                            engineDescription: engine?.description,
                            fuel: fuel?.name,
                            transmission: transmissionDetails?.name,
                            gears: transmissionDetails?.transGears,
                            driveTrain: driveTrainDetails?.name,
                            exteriorColor: exteriorColor?.color,
                            interiorColor: interiorColor?.color,
                            stockNumber: stockNo,
                            serialNumber: serialNo,
                        }}
                    />

                    {!newVehicle && (
                        <VehicleHistory
                            vehicle={vehicle}
                            domain={domain}
                            pastOwner={pastOwner}
                            severelyDamagedVehicle={severelyDamagedVehicle}
                            repairHistory={repairHistory}
                            futureReparations={futureReparations}
                            previousUse={previousUse}
                        />
                    )}

                    {paymentOptions?.disclaimer && <VehicleDisclaimer disclaimer={paymentOptions.disclaimer} />}

                    {newVehicle && (vehiclePromo.length > 0 || articleVehicle.length > 0) && (
                        <section className={`${baseClass}__promotionArticle`}>
                            {vehiclePromo.length > 0 && <PromotionCard promotion={vehiclePromo.promotions[0]} />}
                            {articleVehicle.length > 0 && <ArticleCard article={articleVehicle[0]} />}
                        </section>
                    )}

                    <div className={`${baseClass}__similar`}>
                        <CarouselSimilar
                            vehicleList={similarVehicles}
                            hubName={hubName}
                            pictureConfiguration={pictureConfiguration}
                        />
                    </div>
                </div>
            </main>

            <aside className={`${baseClass}__aside`}>
                <section className={`${baseClass}__sticky`}>
                    <div className='vehicleInfo'>
                        <div className='vehicleInfo__container'>
                            <VehicleName
                                year={year}
                                makeName={make?.name}
                                modelName={model?.name}
                                trimName={trim?.name}
                                isFullName
                            />

                            <div className='vehicleTabs'>
                                <PaymentOptions
                                    newVehicle={newVehicle}
                                    paymentOptions={paymentOptions}
                                    isDetailView
                                    calculatorWidget={calculatorWidget}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </aside>
        </div>
    )
}

export default VdpAlpha
