import axios from 'axios'
import { buildUrlWithParameters } from '@sm360/helpers'

// TODO: UnComment this after adding REACT_APP_PAYMENT_CALCULATOR_WIDGET_URL into kubernetes
// const PAYMENT_CALCULATOR_WIDGET_URL = apiUrlEnv('REACT_APP_PAYMENT_CALCULATOR_WIDGET_URL')

// TODO: Remove this after adding REACT_APP_PAYMENT_CALCULATOR_WIDGET_URL into kubernetes
const PAYMENT_CALCULATOR_WIDGET_URL = 'https://widget-payment-calculator-360.sm360.ca'

const config = {
    headers: { authorization: `Basic c20zNjA6c20zNjA=` },
}

/**
 * Get Translations
 * @param { String } lang - Language
 * @param { String } domain - Domain
 * @returns {Promise<{}>}
 */
export const getTranslationsAPIUrl = async (lang, domain) => {
    const apiUrl = `/${lang}/website/api/config/tags`

    const location = domain || window?.web_domain
    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    try {
        const response = await axios.get(urlComplete, config)
        return response.data
    } catch (e) {
        return Promise.reject(e)
    }
}

/**
 * Get widget payment calculator
 * @param { String } lang - Language
 * @param { String } vehicleId - Vehicle Id
 * @param { Object } dealerInfo - Dealer info with orgId, orgUnitId, location etc...
 * @returns {Promise<{}>}
 */
export const getCalculatorApiUrl = async (lang, vehicleId, dealerInfo) => {
    const { orgId, orgUnitId, location } = dealerInfo

    const baseUrl = `${PAYMENT_CALCULATOR_WIDGET_URL}/payment-calculator-360/render/modal?`

    const paramsUrl = {
        location,
        orgId,
        orgUnitId,
        lang,
        vehicleId,
    }

    const apiUrl = buildUrlWithParameters(baseUrl, paramsUrl)
    let data = {}
    try {
        const response = await axios.get(apiUrl)
        data = await response.data
    } catch (error) {
        console.log(error)
    }
    return data
}
