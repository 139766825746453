import React from 'react'

const initialState = {
    isFetching: false,
    vehicle: {},
    similarVehicles: [],
    catalogSummary: {},
    extendedOrganizationUnit: {},
    hubName: '',
    vehiclePromo: {},
    showroom360ConfigVdp: {},
    quoteId: '',
    calculatorWidget: '',
    articleVehicle: [],
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'fetching': {
            return {
                ...state,
                isFetching: true,
            }
        }
        case 'fetchSucceed': {
            return {
                ...state,
                isFetching: false,
            }
        }
        case 'updateState': {
            return {
                ...state,
                ...action.state,
            }
        }
        case 'init': {
            return {
                ...state,
                vehicle: action.vehicle,
                similarVehicles: action.similarVehicles,
                catalogSummary: action.catalogSummary,
                extendedOrganizationUnit: action.extendedOrganizationUnit,
                isFetching: true,
                domain: action.domain,
                hubName: action.hubName,
                vehiclePromo: action.vehiclePromo,
                showroom360ConfigVdp: action.showroom360ConfigVdp,
                quoteId: action.quoteId,
                calculatorWidget: action.calculatorWidget,
                articleVehicle: action.articleVehicle,
            }
        }
        default:
            return state
    }
}

const VdpContext = React.createContext(initialState)

const VdpProvider = ({ initialData, children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    if (!state.isFetching) {
        if (initialData) {
            dispatch({ type: 'init', ...initialData })
        }
    }

    return <VdpContext.Provider value={{ state, dispatch }}>{children}</VdpContext.Provider>
}

VdpProvider.propTypes = {}

VdpProvider.defaultProps = {}

export { VdpContext, VdpProvider }
